<template>
    <div>
        <b-card>
            <template #header>
                <b-card-title>Additional Vehicle Coverage</b-card-title>
            </template>

            <b-card-body>
                <div v-for="coverage in additionalVehicleCoverage">
                    <div v-if="coverage.name != 'Windshield Cover'">
                        <div class="d-flex mb-2">
                            <h4 class="mb-0">{{coverage.name}}</h4>
                            <b-form-checkbox v-model="coverage.checked" switch class="ml-auto" @change="updateHandler"></b-form-checkbox>
                        </div>
                        <p>{{coverage.description}}</p>
                        <hr />
                    </div>
                    <div v-else>
                        <div class="d-flex mb-2">
                            <h4 class="mb-0">{{coverage.name}}</h4>
                            <b-form-checkbox v-model="coverage.checked" switch class="ml-auto" @change="updateHandler"></b-form-checkbox>
                        </div>
                        <b-form-group v-if="coverage.checked">
                            <label>Minimun sum covered is RM 500</label>
                            <validation-provider rules="required|min_value:500" name="sum insured" v-slot="{errors}">
                                <b-form-input placeholder="Sum Insured" v-model="coverage.sumInsured" />
                                <span class="error">{{ errors[0] }}</span>
                            </validation-provider>
                        </b-form-group>
                        <p><small>Value must be defined, any shortage during claim may impact your NCD</small></p>
                        <p>{{coverage.description}}</p>
                        <hr />
                    </div>
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import { BCard, BCardBody, BCardHeader, BCardTitle, BCol, BFormGroup, BFormInput, BRow, BFormCheckbox } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import { required, min_value } from '@validations'

export default {
    name: "AdditionalCoverageDetails",
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BFormGroup,
        BFormInput,
        BCardTitle,
        BFormCheckbox,
        ValidationProvider,
        required,
        min_value
    },
    data() {
        return {
            additionalVehicleCoverage: [
                {
                    name: "Legal Liability of Passengers for Negligent Acts",
                    description: "(L.L.o.P) - This protects you against a third-party claim for damages from the negligence of your passengers (e.g. damage to another vehicle when your passenger(s) open the car door).",
                    ExtCovCode: "72",
                    checked: false
                },
                {
                    name: "Inclusion of Special Perils",
                    description: "Coverage to cover your vehicle against loss or damage caused by natural perils such as floods, storms, typhoons, volcanic eruptions, earthquakes, landslides or sediment",
                    ExtCovCode: "57",
                    checked: false
                },
                {
                    name: "Strike, Riot and Civil Commotion",
                    description: "This covers the repair costs to your vehicle for damages caused by strikes, riots, and civil commotion.",
                    ExtCovCode: "25",
                    checked: false
                },
                {
                    name: "Windshield Cover",
                    description: "With the windscreen insurance cover, you can be rest assured that the cost to repair or replace your shattered windscreen will be paid for (without affecting your NCD) by your insurer",
                    ExtCovCode: "89A",
                    checked: false,
                    sumInsured: null
                }
            ]
        }
    },
    methods: {
        updateHandler(e) {
            this.$emit('update', this.additionalVehicleCoverage)
        }
    }
}
</script>