<template>
    <div>
        <b-card>
            <template #header>
                <b-card-title>Customer Details</b-card-title>
            </template>
            <b-card-body>
                <b-form-group>
                    <label>Participant Type</label>
                    <validation-provider name="participant type" rules="required" v-slot="{ errors }">
                        <b-form-select id="bd-ptype" placeholder="Participant Type" :options="participantTypeOptions" v-model="BasicDetails.insIndicator" @change="updateHandler" />
                        <span class="error">{{ errors[0] }}</span>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Identity Type" label-for="bd-itype">
                    <validation-provider name="identity type" rules="required" v-slot="{ errors }">
                        <b-form-select id="bd-itype" placeholder="Identity Type" :options="identityTypeOptions" v-model="identityType" @change="updateHandler" />
                        <span class="error">{{ errors[0] }}</span>
                    </validation-provider>
                </b-form-group>

                <b-form-group v-show="identityType !== '' && identityType === 'nric'">
                    <label>IC Number</label>
                    <validation-provider name="IC number" rules="required" v-slot="{ errors }">
                        <b-form-input placeholder="IC Number" v-mask="'############'" v-on:change="dob" v-model="BasicDetails.newIC" @change="updateHandler" />
                        <span class="error">{{ errors[0] }}</span>
                    </validation-provider>
                </b-form-group>

                <b-form-group v-show="identityType !== '' && identityType !== 'nric'">
                    <label>Other ID</label>
                    <validation-provider name="others ID" :rules="identityType === 'nric' ? null : 'required'" v-slot="{ errors }">
                        <b-form-input placeholder="Other ID" v-model="BasicDetails.othersID" @change="updateHandler" />
                        <span class="error">{{ errors[0] }}</span>
                    </validation-provider>
                </b-form-group>

                <b-form-group>
                    <label>Name</label>
                    <b-form-input placeholder="Name" v-model="BasicDetails.name" @change="updateHandler" />
                </b-form-group>

                <b-form-group v-show="BasicDetails.insIndicator === 'P'">
                    <label>Date of Birth</label>
                    <b-form-input placeholder="dd/mm/yyyy" v-model="BasicDetails.dateOfBirth" @change="updateHandler" v-mask="'##/##/####'" />
                </b-form-group>

                <b-form-group v-show="BasicDetails.insIndicator === 'P'">
                    <label>Age</label>
                    <b-form-input placeholder="Age" v-model="BasicDetails.age" @change="updateHandler" />
                </b-form-group>

                <b-form-group v-show="BasicDetails.insIndicator === 'P'">
                    <label>Gender</label>
                    <b-form-select id="bd-gender" placeholder="Gender" :options="genderOptions" v-model="BasicDetails.gender" @change="updateHandler" />
                </b-form-group>

                <b-form-group v-show="BasicDetails.insIndicator === 'P'">
                    <label>Occupation</label>
                    <b-form-select id="bd-occupation" placeholder="Occupation" :options="occupationOptions" v-model="BasicDetails.occupation" @change="updateHandler" />
                </b-form-group>

                <b-form-group v-show="BasicDetails.insIndicator === 'P'">
                    <label>Marital Status</label>
                    <b-form-select id="bd-maritalsts" :options="maritalStatusOptions" v-model="BasicDetails.maritalSts" @change="updateHandler" />
                </b-form-group>

                <b-form-group>
                    <label>Email Address</label>
                    <validation-provider name="email address" :rules="BasicDetails.mobileNo === '' ? 'required|email' : 'email'" v-slot="{ errors }">
                        <b-form-input placeholder="Email Address" v-model="BasicDetails.email" @change="updateHandler" />
                        <span class="error">{{ errors[0] }}</span>
                    </validation-provider>
                </b-form-group>

                <b-form-group>
                    <label>Mobile Phone No.</label>
                    <validation-provider name="phone no." :rules="BasicDetails.email === '' ? 'required|phoneNumber' : 'phoneNumber'" v-slot="{ errors }">
                        <b-form-input placeholder="Example: 0123456789" v-model="BasicDetails.mobileNo" @change="updateHandler" v-mask="'+6###-########'" />
                        <span class="error">{{ errors[0] }}</span>
                    </validation-provider>
                </b-form-group>

                <b-form-group>
                    <label>Address</label>
                    <b-form-input placeholder="Address" v-model="BasicDetails.address" @change="updateHandler" />
                </b-form-group>

                <b-form-group>
                    <label>Postcode</label>
                    <b-form-input placeholder="Postcode" v-model="BasicDetails.postcode" @change="updateHandler" v-mask="'#####'" />
                </b-form-group>

                <b-form-group>
                    <label>State</label>
                    <b-form-select id="bd-state" placeholder="State" :options="stateOptions" v-model="BasicDetails.state" @change="updateHandler" />
                </b-form-group>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import { genderOptions, identityTypeOptions, maritalStatusOptions, occupationOptions, participantTypeOptions, stateOptions } from "@/helpers/optionsHelper";
import { BCard, BCardBody, BCardHeader, BCardTitle, BCol, BFormGroup, BFormInput, BFormSelect, BRow } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import { required, email } from "@validations";

export default {
    name: "CustomerDetails",
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BFormGroup,
        BFormInput,
        BCardTitle,
        BFormSelect,
        ValidationProvider,
        required,
        email
    },
    data() {
        return {
            genderOptions,
            participantTypeOptions,
            identityTypeOptions,
            maritalStatusOptions,
            occupationOptions,
            stateOptions,
            identityType: "",
            BasicDetails: {
                name: "",
                maritalSts: "",
                insIndicator: "",
                newIC: "",
                othersID: "",
                dateOfBirth: "",
                gender: "",
                occupation: "",
                age: "",
                address: "",
                postcode: "",
                state: "",
                mobileNo: "",
                email: ""
            },
        }
    },
    methods: {
        updateHandler() {
            this.$emit('update', { BasicDetails: this.BasicDetails, identityType: this.identityType });
        },
        dob() {
            const ic = this.BasicDetails.newIC;

            if (ic.length < 12)
                this.BasicDetails.dateOfBirth = this.BasicDetails.age = null;
            else {
                const day = ic.substring(4, 6);
                const month = ic.substring(2, 4);
                let year = ic.substring(0, 2);
                const currentYear = this.$date().format("YYYY");
                const currentDay = this.$date().format("DD");
                const currentMonth = this.$date().format("MM");

                year = currentYear < year ? parseInt(19 + year) : parseInt(20 + year);
                var age = this.$date().year() - year;
                if (currentMonth < month) {
                    age = age - 1;
                } else if (currentMonth == month) {
                    if (currentDay < day) {
                        age = age - 1;
                    }
                }

                this.BasicDetails.dateOfBirth = `${day}/${month}/${year}`;
                this.BasicDetails.age = age;
            }
        }
    }
}
</script>