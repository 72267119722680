export const genderOptions = [
  { value: "", text: "Please Select" },
  { value: "M", text: "Male" },
  { value: "F", text: "Female" },
];

export const participantTypeOptions = [
  { value: "", text: "Please Select" },
  { value: "P", text: "Private" },
  { value: "C", text: "Company" },
];

export const identityTypeOptions = [
  { value: "", text: "Please Select" },
  { value: "nric", text: "IC Number" },
  { value: "passport", text: "Passport" },
  { value: "company", text: "Company Number" },
  { value: "police", text: "Police" },
  { value: "army", text: "Army" },
];

export const maritalStatusOptions = [
  { value: "", text: "Please Select" },
  { value: "D", text: "Divorced" },
  { value: "M", text: "Married" },
  { value: "S", text: "Single" },
  { value: "W", text: "Widowed" },
];

export const occupationOptions = [
  { value: "", text: "Please Select" },
  { value: "01", text: "Office Worker" },
  { value: "03", text: "Sales or Service Personnel (not in office)" },
  { value: "04", text: "Other Professional (doctors,accountant, etc.)" },
  { value: "05", text: "Unemployed" },
  { value: "06", text: "Homemakers" },
  { value: "07", text: "Professional Drivers" },
  { value: "08", text: "Student" },
  { value: "09", text: "Retired Persons" },
  { value: "10", text: "Uniformed Personnel" },
  { value: "99", text: "Others" },
];

export const stateOptions = [
  { value: "", text: "Please Select" },
  { value: "01", text: "PERLIS INDERA KAYANGAN" },
  { value: "02", text: "KEDAH DARUL AMAN" },
  { value: "03", text: "PULAU PINANG" },
  { value: "04", text: "PERAK DARUL RIDZUAN" },
  { value: "05", text: "SELANGOR DARUL EHSAN" },
  { value: "06", text: "WILAYAH PERSEKUTUAN" },
  { value: "07", text: "JOHOR DARUL TAKZIM" },
  { value: "08", text: "MELAKA" },
  { value: "09", text: "NEGERI SEMBILAN DARUL KHUSUS" },
  { value: "10", text: "PAHANG DARUL MAKMUR" },
  { value: "11", text: "TERENGGANU DARUL IMAN" },
  { value: "12", text: "KELANTAN DARUL NAIM" },
  { value: "13", text: "SABAH" },
  { value: "14", text: "SARAWAK" },
  { value: "15", text: "LABUAN" },
  { value: "16", text: "PUTRAJAYA" },
];

export const relationshipOptions = [
  { value: "", text: "Please Select" },
  { value: "1", text: "Parent/Parent-In-Law" },
  { value: "2", text: "Spouse" },
  { value: "3", text: "Child" },
  { value: "4", text: "Sibling/Sibling-in-Law/Cousin/Relative(Aunt,Uncle,Niece,Nephew,etc." },
  { value: "5", text: "Friend/ Co-Workder" },
];
