<template>
    <div>
        <b-card>
            <template #header>
                <b-card-title>Vehicle Details</b-card-title>
            </template>

            <b-card-body>
                <b-form-group>
                    <label>Plate Number</label>
                    <validation-provider rules="required" name="plate number" v-slot="{errors}">
                        <b-form-input placeholder="Plate Number" @input="vehNo = vehNo.toUpperCase()" v-model="vehNo" @change="updateHandler" v-mask="'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNN'" />
                        <span class=" error">{{ errors[0] }}</span>
                    </validation-provider>
                </b-form-group>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import { BCard, BCardBody, BCardHeader, BCol, BRow, BFormGroup, BFormInput, BCardTitle } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import { required } from '@validations'

export default {
    name: "VehicleDetails",
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BFormGroup,
        BFormInput,
        BCardTitle,
        ValidationProvider,
        required
    },
    data() {
        return {
            vehNo: ""
        }
    },
    methods: {
        updateHandler() {
            this.$emit('update', this.vehNo);
        }
    }
} 
</script>
