<template>
    <div>
        <b-card>
            <b-card-body>
                <b-button size="lg" block variant="success" type="submit" :class="{'disabled': invalid}" @click.prevent="submit">Save</b-button>
                <div class="pt-1 pb-3">
                    <hr class="dotted ms-2 me-2">
                </div>

                <div class="mb-3">
                    <div class="mb-2 d-flex">
                        <font-awesome-icon icon="share" /> Send to customer:
                    </div>
                    <div>
                        <font-awesome-icon icon="sms" size="2x" class="p-1 icons cursor-pointer" @click="sms" />
                        <font-awesome-icon :icon="['fab','whatsapp']" size="2x" class="p-1 icons cursor-pointer" @click="whatsapp" />
                        <font-awesome-icon icon="envelope" size="2x" class="p-1 icons cursor-pointer" @click="email" />
                        <font-awesome-icon icon="copy" size="2x" class="p-1 icons cursor-pointer" @click="copy" />
                    </div>
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import { BButton, BCard, BCardBody, BCardHeader, BCol, BRow } from "bootstrap-vue";

export default {
    name: "AddActions",
    props: ['invalid'],
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BButton,
    },
    methods: {
        submit() {
            this.$emit('submit');
        },
        whatsapp() {
            this.$emit('whatsapp');
        },
        email() {
            this.$emit('email');
        },
        sms() {
            this.$emit('sms');
        },
        copy() {
            this.$emit('copy');
        }
    }
}
</script>

<style scoped>
.icons {
    color: black
}
</style>