<template>
    <div>
        <validation-observer ref="observer" v-slot="{ invalid }">
            <b-form @submit.prevent="submitHandler">
                <b-row>
                    <b-col>
                        <VehicleDetailsForm @update="vehicleDetailsHandler" />
                        <AdditionalCoverageDetailsForm @update="additionalCoverageDetailsHandler" />
                    </b-col>
                    <b-col>
                        <CustomerDetailsForm @update="customerDetailsHandler" />
                        <AddActions :invalid="invalid" @submit="submitHandler" @whatsapp="whatsappHandler" @email="emailHandler" @copy="copyHandler" @sms="smsHandler" />
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import { addCustomer, getShareLink } from "@/services/AgentService";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BCol, BForm, BRow } from 'bootstrap-vue';
import { ValidationObserver } from "vee-validate";
import AddActions from './AddActions.vue';
import AdditionalCoverageDetailsForm from './AdditionalCoverageDetailsForm.vue';
import CustomerDetailsForm from './CustomerDetailsForm.vue';
import VehicleDetailsForm from './VehicleDetailsForm.vue';

export default {
    name: "AddCustomer",
    components: {
        BRow,
        BForm,
        BCol,
        CustomerDetailsForm,
        VehicleDetailsForm,
        AdditionalCoverageDetailsForm,
        AddActions,
        ValidationObserver,
        ToastificationContent,
    },
    data() {
        return {
            vehNo: "",
            identityType: "",
            BasicDetails: null,
            additionalCoverage: {}
        }
    },
    methods:
    {
        vehicleDetailsHandler(e) {
            this.vehNo = e;
        },
        customerDetailsHandler(e) {
            this.BasicDetails = e.BasicDetails;
            this.identityType = e.identityType;
        },
        additionalCoverageDetailsHandler(e) {
            this.additionalCoverage = e;
        },
        async submitHandler() {
            const isValid = await this.$refs.observer.validate();
            if (!isValid) return;

            try {
                await addCustomer({
                    ...this.BasicDetails,
                    vehNo: this.vehNo,
                    identityType: this.identityType,
                    additionalCoverage: this.additionalCoverage
                });
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Add Customer Success',
                        icon: 'CheckCircleIcon',
                        variant: 'success'
                    },
                })
            }
            catch (e) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Add Customer Failed',
                        text: e.response.data.message,
                        icon: 'XCircleIcon',
                        variant: 'danger'
                    },
                })
            }
        },
        async whatsappHandler() {
            const isValid = await this.$refs.observer.validate();
            if (!isValid) return;

            try {
                const { cusId } = await addCustomer({
                    ...this.BasicDetails,
                    vehNo: this.vehNo,
                    identityType: this.identityType,
                });


                const link = await getShareLink(cusId, false, true);
                const whatsappLink = `https://api.whatsapp.com/send?phone=${this.BasicDetails.mobileNo.replace("+", "")}&text=${link}`;
                window.open(whatsappLink, "_NEW");
            }
            catch (e) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Add Customer Failed',
                        text: e.response.data.message,
                        icon: 'XCircleIcon',
                        variant: 'danger'
                    },
                })
            }
        },
        async smsHandler() {
            const isValid = await this.$refs.observer.validate();
            if (!isValid) return;

            try {
                const { cusId } = await addCustomer({
                    ...this.BasicDetails,
                    vehNo: this.vehNo,
                    identityType: this.identityType
                });

                const link = await getShareLink(cusId, false);
                const smsLink = `sms:${this.BasicDetails.mobileNo}?body=${link}`;
                window.open(smsLink, "_NEW");
            }
            catch (e) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Add Customer Failed',
                        text: e.response.data.message,
                        icon: 'XCircleIcon',
                        variant: 'danger'
                    },
                })
            }
        },
        async copyHandler() {
            try {
                const isValid = await this.$refs.observer.validate();
                if (!isValid) return;

                const { cusId } = await addCustomer({
                    ...this.BasicDetails,
                    vehNo: this.vehNo,
                    identityType: this.identityType,
                });

                const link = await getShareLink(cusId, false);
                const copyLink = link.replaceAll("%0a", "\r\n");

                await this.$copyText(copyLink);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Text copied',
                        icon: 'CopyIcon',
                        variant: 'success'
                    },
                })
            }
            catch (e) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Can not copy!',
                        icon: 'CopyIcon',
                        variant: 'danger'
                    },
                })
            }
        },
        async emailHandler() {
            const isValid = await this.$refs.observer.validate();
            if (!isValid) return;

            const currentBreakPoint = this.$store.getters["app/currentBreakPoint"];
            const isMobile = currentBreakPoint === "xs";

            try {
                const { cusId } = await addCustomer({
                    ...this.BasicDetails,
                    vehNo: this.vehNo,
                    identityType: this.identityType,
                });
                const link = await getShareLink(cusId, false);

                let emailLink;
                if (isMobile)
                    emailLink = `mailto:${this.BasicDetails.email}?subject=${this.vehNo}%20Instant%20Renewal%20&%20quotation%20link&body=${link}`;
                else
                    emailLink = `https://mail.google.com/mail/u/0/?fs=1&to=${this.BasicDetails.email}&su=${this.vehNo}%20instant%20renewal%20&%20quotation%20link&body=${link}&tf=cm`;
                window.open(emailLink, "_NEW");
            }
            catch (e) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Add Customer Failed',
                        text: e.response.data.message,
                        icon: 'XCircleIcon',
                        variant: 'danger'
                    },
                })
            }
        }
    }
}
</script>
